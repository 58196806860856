import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@material-ui/core';
// components
import { ChatWidget } from '@papercups-io/chat-widget';

import Logo from 'components/Logo';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import MainFooter from './MainFooter';
import MainNavbar from './MainNavbar';

// ----------------------------------------------------------------------
const MainStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.neutral
}));

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <>
      <MainNavbar />
      <MainStyle>
        <Outlet />
      </MainStyle>
      <MainFooter />
    </>
  );
}
