import {
  NavLink as RouterLink,
  Link as RouterLink2,
  useLocation,
  NavLinkProps
} from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Stack,
  Link,
  Typography,
  Button,
  AppBar,
  Toolbar,
  Container
} from '@material-ui/core';
// hooks
import { PATH_AUTH, PATH_PAGE, PATH_PLAYER } from 'routes/paths';

import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import DownloadPopup from './DownloadPopup';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: NavLinkProps;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
};

export default function MainNavbar() {
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = true;

  return (
    <AppBar color="transparent" sx={{ boxShadow: 0 }}>
      <MHidden width="smUp">
        <Box
          sx={{
            backgroundColor: 'background.paper'
          }}
        >
          <Container maxWidth="lg">
            <DownloadPopup />
          </Container>
        </Box>
      </MHidden>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Link underline="none" component={RouterLink} to="/">
            <Stack direction="row" spacing={1} alignItems="center">
              <Logo />
              <Typography color="common.white" variant="h4">
                AFROGANE
              </Typography>
            </Stack>
          </Link>
          <MHidden width="mdDown">
            <Box sx={{ flexGrow: 2 }} />
            <>
              <Stack direction="row" spacing={1} alignItems="right">
                <Button
                  sx={{
                    color: 'white'
                  }}
                  variant="text"
                  component={RouterLink2}
                  to={PATH_PAGE.about}
                >
                  About Us
                </Button>
                <Button
                  sx={{
                    color: 'white'
                  }}
                  variant="text"
                  component={RouterLink2}
                  to={PATH_PAGE.iamartist}
                >
                  For Artists
                </Button>

                <Button
                  variant="text"
                  sx={{ marginRight: 2, color: 'white' }}
                  component="a"
                  href="https://app.afrogane.com/auth/login"
                >
                  Login
                </Button>
                <Button
                  variant="contained"
                  component="a"
                  href="https://app.afrogane.com/auth/register"
                >
                  Sign up
                </Button>
              </Stack>
            </>
          </MHidden>

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
