import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import roundGrain from '@iconify/icons-ic/round-grain';
// routes
import {
  EXTERNAL_LOGIN,
  EXTERNAL_REGISTER,
  PATH_AUTH,
  PATH_PAGE,
  PATH_PLAYER
} from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/'
  },
  { title: 'For artist', path: PATH_PAGE.iamartist },
  { title: 'Login', path: EXTERNAL_LOGIN },
  { title: 'Sign Up', path: EXTERNAL_REGISTER },

  { title: 'About us', path: PATH_PAGE.about },
  { title: 'Contact us', path: PATH_PAGE.contact },
  { title: 'FAQs', path: PATH_PAGE.faqs }
  // {
  //   title: 'Pages',
  //   path: '/pages',
  //   icon: <Icon icon={fileFill} {...ICON_SIZE} />,
  //   children: [
  //     {
  //       subheader: 'Other',
  //       items: [
  //         { title: 'About us', path: PATH_PAGE.about },
  //         { title: 'Contact us', path: PATH_PAGE.contact },
  //         { title: 'FAQs', path: PATH_PAGE.faqs }
  //       ]
  //     },

  //     {
  //       subheader: 'Dashboard',
  //       items: [{ title: 'Continue', path: PATH_PLAYER.root }]
  //     }
  //   ]
  // }
];

export default menuConfig;
