import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Grid,
  Link,
  Divider,
  Box,
  Container,
  Typography,
  IconButton,
  Stack
} from '@material-ui/core';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'FaceBook', href: 'https://www.facebook.com/afrogane', icon: facebookFill },
  { name: 'Linkedin', href: 'https://www.instagram.com/afrogane/', icon: linkedinFill },
  { name: 'Twitter', href: 'https://twitter.com/afrogane', icon: twitterFill }
];

const LINKS = [
  {
    headline: 'Afrogane',
    children: [
      { name: 'Open Player ', href: '/' },
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'FAQs', href: PATH_PAGE.faqs }
    ]
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: PATH_PAGE.terms },
      { name: 'Privacy Policy', href: PATH_PAGE.policy }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={8} md={3}>
            <Stack spacing={3}>
              <Stack
                direction="row"
                spacing={2}
                alignItems={{ xs: 'center', md: 'flex-start' }}
                sx={{ mb: { xs: 5, md: 0 } }}
              >
                <ScrollLink to="move_top" spy smooth>
                  <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
                </ScrollLink>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  sx={{ mx: { xs: 'auto', md: 'inherit' } }}
                >
                  AFROGANE
                </Typography>
              </Stack>
              <Stack
                spacing={1.5}
                direction="row"
                justifyContent={{ xs: 'center', md: 'flex-start' }}
                sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
              >
                {SOCIALS.map((social) => (
                  <IconButton
                    target="_blank"
                    href={social.href}
                    key={social.name}
                    color="primary"
                    sx={{ p: 1 }}
                  >
                    <Icon icon={social.icon} />
                  </IconButton>
                ))}
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              <Stack key={LINKS[0].headline} alignItems="center">
                <a href="https://play.google.com/store/apps/details?id=com.afrogane">
                  <Box
                    component="img"
                    src="/static/home/playstore.png"
                    sx={{
                      width: 170,
                      height: 50
                    }}
                  />
                </a>
              </Stack>

              <Stack key={LINKS[0].headline} alignItems="center">
                <a href="https://apps.apple.com/us/app/afrogane-african-music-more/id1557160755">
                  <Box
                    component="img"
                    src="/static/home/appstore.png"
                    sx={{
                      width: 170,
                      height: 50
                    }}
                  />
                </a>
              </Stack>
              <Stack key={LINKS[0].headline} spacing={2}>
                <Typography component="p" variant="overline" color="#FAA74A">
                  {LINKS[0].headline}
                </Typography>
                {LINKS[0].children.map((link) => (
                  <Link
                    to={link.href}
                    key={link.name}
                    color="inherit"
                    variant="body2"
                    component={RouterLink}
                    sx={{ display: 'block' }}
                  >
                    {link.name}
                  </Link>
                ))}
              </Stack>

              <Stack key={LINKS[0].headline} spacing={2}>
                <Typography component="p" variant="overline" color="#FAA74A">
                  {LINKS[1].headline}
                </Typography>
                {LINKS[1].children.map((link) => (
                  <Link
                    to={link.href}
                    key={link.name}
                    color="inherit"
                    variant="body2"
                    component={RouterLink}
                    sx={{ display: 'block' }}
                  >
                    {link.name}
                  </Link>
                ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2022. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
