// @ts-nocheck

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

const localhost = `http://localhost:8080/api`;
const production = `https://afrogane.com/api/`;

export const api = {
  localhost,
  production
};
