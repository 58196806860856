// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

export const ROUTE_INDEX = '/';
const ROOTS_AUTH = '/auth';
const ROOTS_ARTIST_AUTH = '/iamartist/auth';
const ROOTS_DASHBOARD = '/player';
const ARTIST_DASHBOARD = '/artist';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/password-reset'),
  requestResetPassword: path(ROOTS_AUTH, '/request-password-reset'),
  verify: path(ROOTS_AUTH, '/verify'),
  checkEmail: path(ROOTS_AUTH, '/check-email')
};

export const PATH_ARTIST_AUTH = {
  root: ROOTS_ARTIST_AUTH,
  login: path(ROOTS_ARTIST_AUTH, '/login'),
  loginUnprotected: path(ROOTS_ARTIST_AUTH, '/login-unprotected'),
  register: path(ROOTS_ARTIST_AUTH, '/register'),
  registerUnprotected: path(ROOTS_ARTIST_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_ARTIST_AUTH, '/password-reset'),
  requestResetPassword: path(ROOTS_ARTIST_AUTH, '/request-password-reset'),
  verify: path(ROOTS_ARTIST_AUTH, '/verify'),
  checkEmail: path(ROOTS_ARTIST_AUTH, '/check-email'),
  complete: path(ROOTS_ARTIST_AUTH, '/complete')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  iamartist: '/iamartist',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  policy: '/privacy-policy',
  terms: '/terms',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_PLAYER = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    home: path(ROOTS_DASHBOARD, '/home'),
    search: path(ROOTS_DASHBOARD, '/search'),
    genre: path(ROOTS_DASHBOARD, '/genres'),
    region: path(ROOTS_DASHBOARD, '/regions'),
    countries: path(ROOTS_DASHBOARD, '/countries'),
    album: path(ROOTS_DASHBOARD, '/album/'),
    artist: path(ROOTS_DASHBOARD, '/artist/'),
    playlist: path(ROOTS_DASHBOARD, '/playlist/'),
    myplaylist: path(ROOTS_DASHBOARD, '/myplaylist/')
  },
  library: {
    root: path(ROOTS_DASHBOARD, '/library'),
    artist: path(ROOTS_DASHBOARD, '/library/artists'),
    album: path(ROOTS_DASHBOARD, '/library/albums'),
    songs: path(ROOTS_DASHBOARD, '/library/songs'),
    playlist: path(ROOTS_DASHBOARD, '/library/playlists')
  },
  browse: {
    root: path(ROOTS_DASHBOARD, '/browse'),
    region: path(ROOTS_DASHBOARD, '/browse/regions'),
    genre: path(ROOTS_DASHBOARD, '/browse/genres')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/ada-lindgren/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  }
};

export const PATH_ARTIST = {
  root: ARTIST_DASHBOARD,
  checkEmail: path(ROOTS_AUTH, '/check-email'),
  general: {
    uploud: path(ARTIST_DASHBOARD, '/uploud'),
    uploudsingle: path(ARTIST_DASHBOARD, '/uploud/single'),
    uploudmutliple: path(ARTIST_DASHBOARD, '/uploud/album'),
    audience: path(ARTIST_DASHBOARD, '/audience')
  },

  user: {
    root: path(ARTIST_DASHBOARD, '/user'),
    profile: path(ARTIST_DASHBOARD, '/user/profile'),
    account: path(ARTIST_DASHBOARD, '/user/account')
  },

  music: {
    root: path(ARTIST_DASHBOARD, '/music'),
    releases: path(ARTIST_DASHBOARD, '/music/release'),
    single: path(ARTIST_DASHBOARD, '/music/single'),
    editSingle: path(ARTIST_DASHBOARD, '/music/single/:releaseId/edit'),
    editRelease: path(ARTIST_DASHBOARD, '/music/release/:releaseId/edit'),
    addSong: path(ARTIST_DASHBOARD, '/music/release/:releaseId/add-song'),
    editSong: path(ARTIST_DASHBOARD, '/music/release/:releaseId/songs/:songId/edit')
  }
};

export const PATH_DOCS = 'https://Afrogane-docs.vercel.app/';
export const EXTERNAL_LOGIN = 'https://app.afrogane.com/auth/login';
export const EXTERNAL_REGISTER = 'https://app.afrogane.com/auth/register';
export const EXTERNAL_ARTIST_REGISTER = 'https://app.afrogane.com/iamartist/auth/register';
export const EXTERNAL_ARTIST_LOGIN = 'https://app.afrogane.com/iamartist/auth/login';
