import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@material-ui/core';
import { ChatWidget } from '@papercups-io/chat-widget';

import MainFooter from './MainFooter';
import MainNavbar from './MainNavbar';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/iamartist/auth/register' || pathname === '/iamartist/auth/login';

  return (
    <>
      {!isHome && <MainNavbar />}
      <div>
        <Outlet />
      </div>
      {!isHome && <MainFooter />}

      <ChatWidget
        // `accountId` is used instead of `token` in older versions
        // of the @papercups-io/chat-widget package (before v1.2.x).
        // You can delete this line if you are on the latest version.
        // accountId="8d73827e-c747-47bf-809f-2eff62e7ad23"
        token="8d73827e-c747-47bf-809f-2eff62e7ad23"
        inbox="9a7a40cc-99ed-4356-8bae-39df3e018f1a"
        title="Welcome to Afrogane"
        subtitle="Ask us anything in the chat window below 😊"
        primaryColor="#fcb900"
        greeting="Hello  ! any question"
        newMessagePlaceholder="Start typing..."
        showAgentAvailability={true}
        agentAvailableText="We're online right now!"
        agentUnavailableText="We're away at the moment."
        requireEmailUpfront={false}
        iconVariant="outlined"
        baseUrl="https://app.papercups.io"
        // Optionally include data about your customer here to identify them
        // customer={{
        //   name: __CUSTOMER__.name,
        //   email: __CUSTOMER__.email,
        //   external_id: __CUSTOMER__.id,
        //   metadata: {
        //     plan: "premium"
        //   }
        // }}
      />
    </>
  );
}
