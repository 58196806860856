import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import roundGrain from '@iconify/icons-ic/round-grain';
// routes
import { PATH_ARTIST_AUTH, PATH_PAGE, PATH_ARTIST } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    path: '/'
  },

  { title: 'Login', path: PATH_ARTIST_AUTH.loginUnprotected },
  { title: 'Register', path: PATH_ARTIST_AUTH.registerUnprotected },

  { title: 'About us', path: PATH_PAGE.about },
  { title: 'Contact us', path: PATH_PAGE.contact },
  { title: 'FAQs', path: PATH_PAGE.faqs }
];

export default menuConfig;
