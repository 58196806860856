import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Button, Box, Divider, MenuItem, Typography, Stack } from '@material-ui/core';
import Logo from 'components/Logo';
// routes
import { PATH_PLAYER } from '../../routes/paths';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function DownloadPopup() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack
        spacing={5}
        direction={{ xs: 'row', md: 'row' }}
        sx={{
          p: 1
        }}
        // justifyContent="center"
      >
        <Logo />
        <Button ref={anchorRef} onClick={handleOpen}>
          Dowload Mobile App
        </Button>
      </Stack>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: '100%' }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
            <Stack alignItems="center">
              <a href="https://play.google.com/store/apps/details?id=com.afrogane">
                <Box
                  component="img"
                  src="/static/home/playstore.png"
                  sx={{
                    width: 170,
                    height: 50
                  }}
                />
              </a>
            </Stack>

            <Stack alignItems="center">
              <a href="https://apps.apple.com/us/app/afrogane-african-music-more/id1557160755">
                <Box
                  component="img"
                  src="/static/home/appstore.png"
                  sx={{
                    width: 170,
                    height: 50
                  }}
                />
              </a>
            </Stack>
          </Stack>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
