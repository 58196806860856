import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Button,
  Box,
  Divider,
  MenuItem,
  CardHeader,
  Typography,
  Stack,
  IconButton,
  Avatar
} from '@material-ui/core';
import {
  ArrowDownwardTwoTone,
  ArrowForwardOutlined,
  Download,
  DownloadTwoTone
} from '@material-ui/icons';
import Logo from 'components/Logo';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function DownloadPopup() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CardHeader
        sx={{
          // m: 0,
          p: 0.5
        }}
        titleTypographyProps={{ color: 'text.primary' }}
        ref={anchorRef}
        action={
          <IconButton onClick={handleOpen}>
            <DownloadTwoTone />
          </IconButton>
        }
        title="Download the Mobile App"
        subheader="for the best experience"
      />

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: '100%' }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
            <Stack alignItems="center">
              <a href="https://play.google.com/store/apps/details?id=com.afrogane">
                <Box
                  component="img"
                  src="/static/home/playstore.png"
                  sx={{
                    width: 170,
                    height: 50
                  }}
                />
              </a>
            </Stack>

            <Stack alignItems="center">
              <a href="https://apps.apple.com/us/app/afrogane-african-music-more/id1557160755">
                <Box
                  component="img"
                  src="/static/home/appstore.png"
                  sx={{
                    width: 170,
                    height: 50
                  }}
                />
              </a>
            </Stack>
          </Stack>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
